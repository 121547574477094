<template>
    <li class="text-sm font-normal font-Poppins text-grey-protostars-500 dark:text-white">
        <slot></slot>
    </li>
</template>

<script>
export default {
    name: "DropdownItem",
    props: {
        class: {
            type: String,
            default: ''
        },
    },
    data() {
        return {
        }
    },
    methods: {
    }
}
</script>